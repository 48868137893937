<template>
  <div class="users">
    <Header back="返回" title="用户管理" index="首页" titleOne="用户管理" beforeTitle="新增/编辑" />
    <div class="content">
      <el-form
        :label-position="labelPosition"
        :model="userform"
        label-width="80px"
        :rules="UserRule"
        ref="UserRel"
      >
        <h1>个人信息</h1>
        <el-form-item label="真实姓名" prop="true_name">
          <el-input v-model="userform.true_name" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="tel">
          <el-input v-model="userform.tel" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="userform.password" placeholder="留空则不修改"></el-input>
        </el-form-item>
        <el-form-item label="邮 箱">
          <el-input v-model="userform.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="入职时间">
          <el-date-picker
            v-model="userform.entry_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同开始">
          <el-date-picker
            v-model="userform.start_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同结束">
          <el-date-picker
            v-model="userform.end_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="userform.status" placeholder="选择状态">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门">
          <el-select v-model="userform.organization_id" @change="getMoptions" placeholder="请选择部门">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色/职位名称" prop="position">
          <el-select v-model="userform.role_id" placeholder="请选择角色/职位">
            <el-option v-for="item in moptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级所属部门" prop="leader_id">
          <el-select
            @change="getLeaderList"
            v-model="userform.leader_organization"
            placeholder="请选择上级所属部门"
          >
            <el-option
              v-for="item in leaderoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级领导" prop="leader_id">
          <el-select v-model="userform.leader_id" placeholder="请选择上级领导">
            <el-option
              v-for="(item,index) in leaderList"
              :key="index"
              :label="item.true_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否主管">
          <el-select v-model="userform.leader" placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-form-item label="现居住地址" class="form_area">
            <el-input class="area_now" placeholder="请输入现居住地" v-model="area"></el-input>
          </el-form-item>
          <el-form-item label="户籍所在地" class="form_area">
            <el-input
              style="width:97%"
              placeholder="请输入户籍所在地"
              v-model="userform.registered_residence"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 上传合同附件 -->
      <div class="upload">
        <el-upload
          class="upload-demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传合同附件</el-button>
        </el-upload>
        <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
      </div>
      <!-- 身份证附件照 -->
      <span style="display: block; margin: 20px 0">身份证</span>
      <div class="id_box">
        <div class="id_flex">
          <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :before-upload="beforeUpload"
          >
            <!-- <i slot="default" class="el-icon-plus"></i> -->
            <span class="id_text">点击上传正面</span>
            <img src="../../assets/images/id_f.png" alt class="id_img" />
            <div slot="file" slot-scope="{ file }">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </div>
        <div class="id_flex">
          <el-upload action="#" list-type="picture-card" :auto-upload="false">
            <!-- <i slot="default" class="el-icon-plus"></i> -->
            <span class="id_text">点击上传反面</span>
            <img src="../../assets/images/id_bs.png" alt class="id_img" />
            <div slot="file" slot-scope="{ file }">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </div>
      </div>
      <Study />
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
      <div class="foot_TJ">
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="onSubmit('UserRel')"
          :disabled="isDisable"
        >确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Study from '../../components/setTing/index.vue'
import axios from 'axios'
import { mapGetters, mapState } from "vuex";
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,

      area: '', //现居住地
      area_home: "",//户籍所在地
      time_in: '', //入职时间
      time_start: '', //合同开始
      time_end: '', //合同结束
      isDisable: false,
      labelPosition: "top",
      options: [],
      moptions: [],
      leaderoptions: [],
      userform: {
        true_name: "",
        tel: "",
        password: "",
        email: "",
        role_id: "",
        position: "",
        leader_id: "",
        leader: '',
        status: "",
      },
      UserRule: {
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "blur",
          }
        ],
        true_name: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        organization_id: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
        role_id: [
          {
            required: true,
            message: "请输入职位",
            trigger: "blur",
          },
        ],
        leader: [
          {
            required: true,
            message: "请选择是否为主管",
            trigger: "change",
          },
        ],
        // leader_id: [
        //   {
        //     required: true,
        //     message: "请选择上级",
        //     trigger: "change",
        //   },
        // ],
      },
      leaderList: [],
    };
  },
  components: {
    Study,
  },
  created () {
    this.getitemId();
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  watch: {
    "userform.organization_id": {
      handler (id) {
        this.getMoptions(id);
      },
      deep: true,
    },
    "userform.leader_organization": {
      handler (id) {
        this.getLeaderList(id);
      },
      deep: true,
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/member/view", {
          params: {
            member_id: this.meber_id,
          },
        })
        .then((res) => {
          res.data.leader = JSON.stringify(res.data.leader);
          res.data.status = JSON.stringify(res.data.status);
          this.userform = res.data;
          this.userform.member_id = +this.meber_id;
        });
    }
  },
  methods: {
    getLeaderList (id) {
      this.axios.get('/api/member/select_member', { params: { organization_id: id, has_self: 0 } }).then((res) => {
        console.log(res, 8888)
        this.leaderList = res.data
      })
    },
    handleRemove (file) {
      console.log(file)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    },

    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },

    httpRequest (param) {
      let avatar = param.file
      const form = new FormData()
      form.append('avatar', avatar)
      this.upload('/api/annex/upload_avatar', form).then((res) => {
        console.log(res)

        this.reload()
      })
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res)
          })
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    // 上传图片前的过滤
    beforeUpload (avatar) {
      const isJPG =
        avatar.type === 'image/png' || 'image/jpg' || 'image/jpeg'
      const isLt2M = avatar.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    onSubmit (name) {
      this.$refs[name].validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/member/store", this.userform)
            .then((res) => {
              this.$router.push("/user/index");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              this.btnLoading = false
              console.log("err:", res);
            });
        } else {
          // this.$message.error("");
          console.log("error");
        }
      });
    },
    getitemId (val) {
      this.axios.get("/api/organization/select_organization").then((res) => {
        console.log(this.options);
        this.options = res.data;
        this.leaderoptions = res.data;
      });
    },
    getMoptions (id) {
      this.axios.get('/api/role/select_organization_role', { params: { organization_id: id } }).then((res) => {
        this.moptions = res.data
      })
    },
    getLeader () {
      let params = this.userform;
      this.axios
        .get("/api/organization/select_organization", { params: { organization_id: params.organization_id } })
        .then((res) => {
          console.log(res, 9999)
          this.axios.get('')
          this.leaderoptions = res.data;
        });
    },
    returnPage () {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped="scoped">
.users .content ul li span {
  display: inline-block;
  color: #cbcfe3;
  margin-right: 40px;
}

.users .content ul li p {
  display: inline-block;
  color: #909399;
}

.users .el-input {
  width: 90%;
}
.el-form-item {
  width: 25%;
  display: inline-block;
}
div/deep/.el-form-item__label {
  color: #404040;
}
div/deep/.el-select {
  width: 90%;
}
::v-deep .el-form-item__error {
  left: 35px !important;
}

.form_area {
  width: 100% !important;
}
.area_now {
  width: 97% !important;
}

.upload {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.upload span {
  margin-left: 15px;
  color: #a6abc7;
}
.upload .ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4;
  border: none;
  color: #909399;
}
div/deep/.el-upload--picture-card {
  width: 100%;
  height: 234px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: center;
  line-height: 234px;
  position: relative;
}
.id_img {
  width: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
}
.id_text {
  z-index: 9;
  position: absolute;
  transform: translateX(-48px);
}
.id_box {
  width: 100%;
  display: flex;
}
.id_flex {
  width: 370px !important;
  margin-right: 24px;
  position: relative;
}
div/deep/.el-upload-list--picture-card .el-upload-list__item {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
  z-index: 199;
}
</style>
