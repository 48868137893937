<template>
  <div>
    <div class="id_box">
      <div class="id_flex">
        <span style="display: inline-block; margin: 20px 0">毕业证书</span>
        <el-upload action="#" list-type="picture-card" :auto-upload="false">
          <!-- <i slot="default" class="el-icon-plus"></i> -->
          <span class="id_text">点击上传毕业证</span>
          <img src="../../assets/images/ssc_book.png" alt class="id_img" mode="widthFix" />
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
      <div class="id_flex">
        <span style="display: block; margin: 20px 0">学士学位证书</span>
        <el-upload action="#" list-type="picture-card" :auto-upload="false">
          <!-- <i slot="default" class="el-icon-plus"></i> -->
          <span class="id_text id_study">点击上传学士学位证</span>
          <img src="../../assets/images/study_book.png" alt class="id_img" />
          <div class="upioad_img" slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
    }
  },
  methods: {
    handleRemove (file) {
      console.log(file)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    },
  }
}
</script>

<style scoped>
div/deep/.el-upload--picture-card {
  width: 100%;
  height: 234px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: center;
  line-height: 234px;
  position: relative;
}
.id_img {
  width: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
}
.id_text {
  width: 100%;
  z-index: 9;
  position: absolute;
  transform: translateX(-50%);
}
.id_box {
  width: 100%;
  /* display: flex; */
}
.id_flex {
  width: 370px !important;
  margin-right: 24px;
  position: relative;
  display: inline-block;
}

div/deep/.el-upload-list--picture-card .el-upload-list__item {
  position: absolute;
  width: 80%;
  height: 66% !important;
  left: 50%;
  top: 27% !important;
  transform: translateX(-50%);
  z-index: 199;
}
.id_study {
  transform: translateX(-50%) !important;
}
</style>